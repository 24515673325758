import React, { Suspense, lazy, useEffect } from 'react'
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom'

import useAppInit from '@sweetspot/apps/partner-portal/hooks/useAppInit'

import LazyLoading from '@sweetspot/apps/partner-portal/components/LazyLoading'
import PrivateRoute from '@sweetspot/apps/partner-portal/components/PrivateRoute'
import { FullScreenLoading } from '@sweetspot/sweetspot-js/common/components/FullScreenLoading'
import { useSelector } from 'react-redux'
import { API_ENV_KEYS } from '@sweetspot/shared/util/constants'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'

// Lazy pages
const AsyncLogin = lazy(() => import('@sweetspot/apps/partner-portal/pages/Login'))
const AsyncLogout = lazy(() => import('@sweetspot/apps/partner-portal/pages/Logout'))
const AsyncTeeSheet = lazy(() => import('@sweetspot/apps/partner-portal/pages/TeeSheet'))
const AsyncBook = lazy(() => import('@sweetspot/apps/partner-portal/pages/Book'))
const AsyncBookers = lazy(() => import('@sweetspot/apps/partner-portal/pages/Bookers'))
const AsyncDashboard = lazy(() => import('@sweetspot/apps/partner-portal/pages/Dashboard'))
const AsyncDeveloper = lazy(() => import('@sweetspot/apps/partner-portal/pages/Developer'))
const AsyncNotFound = lazy(() => import('@sweetspot/apps/partner-portal/pages/NotFound'))
const AsyncNoPartnerships = lazy(() =>
  import('@sweetspot/apps/partner-portal/pages/NoPartnerships')
)
const AsyncMyAccount = lazy(() => import('@sweetspot/apps/partner-portal/pages/MyAccount'))

// Lazy Components
const LazyDefaultLayout = lazy(() =>
  import('@sweetspot/apps/partner-portal/components/DefaultLayout')
)

/**
 * Component that wraps default layout(header and sidemenu) around the page
 *
 * @param {any} props
 */
const RoutesWithDefaultLayout = (props) => {
  const [appReady, hasPartnerships] = useAppInit()
  const { selectedId: selectedPartnership } = useSelector((state) => state.partnerships)
  return (
    <React.Fragment>
      <FullScreenLoading loading={!appReady} />
      <LazyDefaultLayout>
        {!hasPartnerships ? (
          <Switch>
            <Route
              exact
              path="(/|/tee-sheet|/bookings|/bookers|/dashboard|/book)"
              component={AsyncNoPartnerships}
            />
            <Route path="/developer" component={AsyncDeveloper} {...props} />
            <Route exact path="/my-account" component={AsyncMyAccount} {...props} />
            <Route exact path="/logout" component={AsyncLogout} {...props} />
            <Route path="*" component={AsyncNotFound} {...props} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" {...props}>
              <Redirect to="/tee-sheet" />
            </Route>
            <Route exact path="/tee-sheet" {...props}>
              <AsyncTeeSheet key={selectedPartnership} />
            </Route>

            <Route exact path="/book" component={AsyncBook} {...props} />
            <Route exact path="/dashboard" component={AsyncDashboard} {...props} />
            <Route exact path="/my-account" component={AsyncMyAccount} {...props} />
            <Route path="/bookers" component={AsyncBookers} {...props} />
            <Route path="/developer" component={AsyncDeveloper} {...props} />
            <Route exact path="/logout" component={AsyncLogout} {...props} />
            <Route path="*" component={AsyncNotFound} {...props} />
          </Switch>
        )}
      </LazyDefaultLayout>
    </React.Fragment>
  )
}

/**
 * Main App
 */
const App = ({ appVersion = '0.0.0' }) => {
  const { currentUser } = useSelector((state) => state.auth)

  useEffect(() => {
    // Only init logrocket if user is logged in
    if (currentUser?.uuid) {
      const logRocketProject =
        process.env?.REACT_APP_API_ENV === API_ENV_KEYS.PROD
          ? 'ryanlo/partner-portal-prod-ssdxz'
          : 'ryanlo/partner-portal-dev-kukfp'
      const {
        uuid,
        email = 'EmailNotDefined',
        first_name = 'FirstNameNotDefined',
        last_name = 'LastNameNotDefined',
      } = currentUser

      LogRocket.init(logRocketProject, {
        release: appVersion,
      })
      LogRocket.identify(uuid, {
        email,
        name: `${first_name} ${last_name}`,
      })
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.getCurrentScope()?.setExtra('sessionURL', sessionURL)
      })
    }
  }, [currentUser, appVersion])

  return (
    <Router>
      <Switch>
        <Suspense fallback={<LazyLoading />}>
          {/* Routes that don't use the defult layout should be here and before the last route */}
          <Route exact path="/login" component={AsyncLogin} />
          {/* Routes that use the default layout should be placed inside RoutesWithDefaultLayout */}
          <PrivateRoute component={RoutesWithDefaultLayout} />
        </Suspense>
      </Switch>
    </Router>
  )
}

export default App
